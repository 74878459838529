(function () {
  'use strict';

  /* global FormData, Blob, document, MutationObserver */
  class DetalhesAlertaCtrl {
    constructor($q, $timeout, $uibModalInstance, alertas, status, ouId, AlertMessage, FeaturehubJsSdk,
                descricaoStatus, HistoricoAlerta, Alertas, AlertsTag, UsuariosVfleets, Restangular, $translate) {
      this.q = $q;
      this.timeout = $timeout;
      this.modalInstance = $uibModalInstance;
      this.alertMessage = AlertMessage;
      this.historicoService = HistoricoAlerta;
      this.descricaoStatus = descricaoStatus;
      this.alertasService = Alertas;
      this.alertsTagService = AlertsTag;
      this.alertTag = null;
      this.alertsTag = [];
      this.ouId = ouId;
      this.isAlertsTagFeatureEnabled = FeaturehubJsSdk.isFeatureEnabled('TTLAINT1551_alerts_tag_hist_alerta');
      this.usuarioService = UsuariosVfleets;
      this.alertas = alertas;
      this.status = angular.isDefined(status) ? status : null;
      this.historico = [];
      this.comentario = '';
      this.usuario = null;
      this.usuarios = [];
      this.alerta = {};
      this.anexos = [];
      this.shouldSendAttachmentProntuario = false;
      this.restangular = Restangular;
      this.translate = $translate;
      this.stringPara = this.translate.instant('ce.analise.alertas.detalhes.para');
      /*eslint-disable */
      this.statusMap = {
        [this.translate.instant('ce.analise.alertas.detalhes.atribuido')]: 'ATRIBUIDO',
        [this.translate.instant('ce.analise.alertas.detalhes.resolvido')]: 'RESOLVIDO'
      };
      /*eslint-enable */
      this.statusLista = Object.keys(this.statusMap);
      this.loadHistorico();
      this.loadUsuarios();
      this.loadAttachmentSquare();
      if (this.isAlertsTagFeatureEnabled) {
        this.loadAlertsTag();
      }
      this.REFUSED = 0;
      this.ACCEPT = 1;
    }

    loadHistorico() {
      if (this.alertas.length === 1) {
        this.historicoService.one(this.alertas[0].id).get({datahora: this.alertas[0].datahora})
          .then(data => {
            this.historico = data;
          });
      }
    }

    loadUsuarios() {
      this.usuarioService.getUsuariosAtribuiveis(this.alertas.map(v => v.veiculo.id))
        .then(data => this.usuarios = this.sortUsuariosByLogin(data));
    }

    loadAlertsTag() {
      this.alertsTagService.get(this.ouId)
        .then(data => this.alertsTag = data);
    }

    sortUsuariosByLogin(list) {
      if (angular.isArray(list)) {
        return list.sort((a, b) => a.login.localeCompare(b.login));
      }
    }

    tratativaAlertaRequest() {
      const veiculoId = this.alertas[0].veiculo.id,
          params = {
            inicio: this.alertas[0].datahora,
            fim: this.alertas[this.alertas.length - 1].datahora,
            shouldSendAnexoProntuario: this.shouldSendAttachmentProntuario ? this.ACCEPT : this.REFUSED
          },
          formData = this.buildFormData();
      return this.restangular.all(`alerta/alertas/${veiculoId}/tratativa-alertas`)
      .withHttpConfig({transformRequest: angular.identity})
      .customPOST(formData, undefined, params, {'Content-Type': undefined})
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.ranking.motoristas.prontuario.modal.erroSalvar'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    buildFormData() {
      const alertaIds = this.alertas.map((alerta) => alerta.id),
          motoristasId = this.alertas.map((alerta) => alerta.motoristaId);
      let formData = new FormData(),
          response = {
            alertasIds: alertaIds,
            motoristasIds: motoristasId,
            status: this.status !== null ? this.statusMap[this.status] : null,
            comentario: this.comentario,
            usuarioAtribuido: this.usuario
          };
      if (this.anexos.length > 0) {
        this.anexos.forEach(file =>
          formData.append('file', file.file)
          );
      }
      if (this.alertTag && this.alertTag.id) {
        response.tagId = this.alertTag.id;
      }
      formData.append('content', new Blob([angular.toJson(response)], {type: 'application/json'}));
      return formData;
    }

    salvar() {
      if (this.validar()) {
        if (this.shouldOpenModalConfirmProntuario()) {
          this.loadModalConfirmProntuario()
            .then(() => {
              this.tratativaAlertaRequest()
              .then(() => {
                this.fechar();
              });
            }).catch(() => {});
        } else {
          this.tratativaAlertaRequest()
          .then(() => {
            this.fechar();
          });
        }
      }
    }

    validar() {
      if (this.comentario === '' && this.status === null && this.anexos.length === 0) {
        this.exibirAlerta('warning', `ce.analise.alertas.detalhes.validarAlteracao`);
        return false;
      }
      if (this.status === this.translate.instant('ce.analise.alertas.detalhes.atribuido') && this.usuario === null) {
        this.exibirAlerta('warning', 'ce.analise.alertas.detalhes.validarUsuario');
        return false;
      }
      return true;
    }

    isDisabled() {
      const veiculoIds = this.alertas.map(alerta => alerta.veiculo.id);
      return this.hasVeiculosDiferentes(veiculoIds);
    }

    hasVeiculosDiferentes(veiculoIds) {
      return veiculoIds.some(id => id !== veiculoIds[0]);
    }

    loadAttachmentSquare() {
      this.timeout(() => {
        this.waitForAttachmentElement('#attachmentSquare');
      }, 0);
    }

    waitForAttachmentElement(selector) {
      /* eslint-disable */
      const observer = new MutationObserver((mutations) => {
        const attachmentElementContainer = document.querySelector(selector);
        if (mutations.length > 0) {
          observer.disconnect();
          const attachmentSquareElement = document.createElement('vfwc-external-button-attachment-square');
          attachmentSquareElement.isDisabled = this.isDisabled();
          attachmentElementContainer.appendChild(attachmentSquareElement);
          this.onFileAttached(attachmentSquareElement);
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    }

    loadModalConfirmProntuario() {
      return new Promise((resolve, reject) => {
          /* eslint-disable */
        const confirmProntuarioElementContainer = document.querySelector('#modalConfirmProntuario');
        const confirmProntuarioElement = document.createElement('vfwc-external-confirm-dialog-anexo-alerta-prontuario');
        confirmProntuarioElement.motoristas = this.alertas
          .filter(alerta => alerta.motoristaId)
          .map(alerta => alerta.motorista);
        confirmProntuarioElementContainer.appendChild(confirmProntuarioElement);
        this.onConfirmProntuario(confirmProntuarioElement, resolve, reject);
      });
    }

    onConfirmProntuario(element, resolve, reject) {
      element.addEventListener('userAnswerEvent', (event) => {
        if (event.detail) {
          switch(event.detail.shouldSendProntuario) {
            case this.ACCEPT:
              this.shouldSendAttachmentProntuario = true;
              break;
            case this.REFUSED:
              this.shouldSendAttachmentProntuario = false;
              break;
            default:
              reject();       
          }
        }
        resolve();
      });
    }

    onFileAttached(element) {
      element.addEventListener('fileAttachedEvent', (event) => {
        if (event.detail && event.detail.fileAttached) {
          this.anexos = event.detail.attached;
        } else {
          this.anexos = [];
        }
      });
    }

    shouldOpenModalConfirmProntuario() {
      const hasMotoristas = this.alertas.some(alerta => alerta.motoristaId),
          hasFilesAttached = this.anexos.length > 0;
      return hasMotoristas && hasFilesAttached;
    }

    fechar() {
      this.modalInstance.dismiss('cancel');
    }

    exibirAlerta(type, msg) {
      this.alerta = {
        show: true,
        type: type,
        msg: this.translate.instant(msg)
      };
    }
  }

  angular
    .module('historicoAlerta')
    .controller('DetalhesAlertaCtrl', DetalhesAlertaCtrl);
}());
