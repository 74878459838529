(function () {
  'use strict';

  const LEFT_CLICK = 0,
      MIDDLE_CLICK = 1;

  class EventViewerLinkCtrl {
    constructor($uibModal, Authenticator, $translate, $window, safetyService, $document, $scope, FeaturehubJsSdk, FeatureOuService, FeatureHubConfigKeys, DecoratedVideoPlayerService) {
      this.uibModal = $uibModal;
      this.authenticator = Authenticator;
      this.translate = $translate;
      this.window = $window;
      this.safetyService = safetyService;
      this.document = $document[0];
      this.scope = $scope;
      this.user = null;
      this.FeatureOuService = FeatureOuService;
      this.decoratedVideoPlayerService = DecoratedVideoPlayerService;

      this.visualizar = this.translate.instant('ce.common.visualizar');
      this.tooltip = this.snapshotId ? this.visualizar : this.translate.instant('ce.common.visualizarVideo');
      this.verVideo = this.translate.instant('ce.common.verVideo');
      this.temPermissaoVisualizacaoVideo = this.authenticator.hasRole('VIDEO_VISUALIZAR');
      this.text = this.snapshotId ? this.visualizar : this.verVideo;
      this.isPremiumTelemetryEnabled = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.FEATURE_PREMIUM_TELEMETRY);
      this.isDecoratedVideoPlayerEnabled = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.DECORATED_VIDEO_PLAYER);

      this.initUserConfig();
    }

    initUserConfig() {
      this.authenticator.getUser().then(user => {
        this.user = user;
        this.getDecoratedVideoPlayerConfig(user.uo.id);
      });
    }

    getDecoratedVideoPlayerConfig(userUoId) {
      this.FeatureOuService.getDecoratedVideoPlayerConfig(userUoId).then((decoratedVideoPlayer) => {
        this.isDecoratedVideoPlayerNAFlagActivated = decoratedVideoPlayer;
      }).catch((error) => {
        this.log.error('Error loading featureOu config: ', error);
      });
    }

    shouldDisplayDecoratedVideoPlayer() {
      return this.isDecoratedVideoPlayerEnabled && this.isDecoratedVideoPlayerNAFlagActivated;
    }

    linkText() {
      return this.hideText ? '' : ' ' + this.text;
    }

    exibirPlayer(event) {
      const isClickOpenInNewTab = event.ctrlKey && event.button === LEFT_CLICK || event.button === MIDDLE_CLICK;
      if (isClickOpenInNewTab && this.idVideo) {
        this.window.open(`/video/${this.idVideo}/${this.datahora}`, '_blank');
      } else if (isClickOpenInNewTab && this.snapshotId) {
        this.window.open(
          `/video/${this.snapshotId}/${this.datahora}/${this.latitude}/${this.longitude}` +
          `/${this.tipoEvento}/${this.vehicleId}/${this.vehiclePrefix}/${this.vehiclePlate}/${this.driverName}` +
          `/${this.threshold}/${this.eventDuration}/${this.actualValue}/${this.eventLevel}`,
          '_blank'
        );
      } else if (event.button === LEFT_CLICK) {
        if (this.shouldDisplayDecoratedVideoPlayer()) {
          this.loadDecoratedVideoPlayer();
        } else {
          this.uibModal.open({
            component: 'multiVideoPlayer',
            windowTopClass: 'player-container',
            backdrop: false,
            resolve: {
              idVideo: () => this.idVideo,
              snapshotId: () => this.snapshotId,
              datahora: () => this.datahora,
              tipoEvento: () => this.tipoEvento,
              latitude: () => this.latitude,
              longitude: () => this.longitude,
              vehicleId: () => this.vehicleId,
              vehiclePrefix: () => this.vehiclePrefix,
              vehiclePlate: () => this.vehiclePlate,
              driverName: () => this.driverName,
              threshold: () => this.threshold,
              eventDuration: () => this.eventDuration,
              actualValue: () => this.actualValue,
              eventLevel: () => this.eventLevel,
              isPremiumTelemetryEnabled: () => this.isPremiumTelemetryEnabled
            }
          });
        }
      }
    }

    loadDecoratedVideoPlayer() {
      const props = {
        idVideo: this.idVideo,
        snapshotId: this.snapshotId,
        datahora: this.datahora,
        tipoEvento: this.tipoEvento,
        latitude: this.latitude,
        longitude: this.longitude,
        vehicleId: this.vehicleId,
        vehiclePrefix: this.vehiclePrefix,
        vehiclePlate: this.vehiclePlate,
        driverName: this.driverName,
        threshold: this.threshold,
        eventDuration: this.eventDuration,
        actualValue: this.actualValue,
        eventLevel: this.eventLevel,
        user: this.user,
        handleVideoExport: (isBlur, videoType) => this.handleVideoExport(isBlur, videoType)
      };
      this.decoratedVideoPlayerService.loadDecoratedVideoPlayer(props);
    }

    handleVideoExport(isBlurButton, videoType) {
      this.window.videoService.initializeList();
      const videoExport = {id: this.idVideo, type: videoType, status: 'PENDING'};
      switch (videoType) {
        case 'STANDARD':
          this.window.videoService.standardVideoExport = videoExport;
          break;
        case 'BLUR':
          this.window.videoService.blurVideoExport = videoExport;
          break;
        case 'INDIVIDUAL_STANDARD':
          this.window.videoService.indivStandardVideoExport = videoExport;
          break;
        case 'INDIVIDUAL_BLUR':
          this.window.videoService.indivBlurVideoExport = videoExport;
          break;
        default:
          throw new Error(`Unknown export type: ${videoType}`);
      }
      this.window.videoService.setVideoId(this.idVideo);
      this.window.videoService.setVehicleId(this.vehicleId);
      this.window.videoService.handleVideoExport(isBlurButton, videoType);
    }

    shouldShowLink() {
      return this.idVideo || this.snapshotId;
    }
  }

  EventViewerLinkCtrl.$inject = ['$uibModal', 'Authenticator', '$translate', '$window', 'safetyService', '$document', '$scope', 'FeaturehubJsSdk', 'FeatureOuService', 'FeatureHubConfigKeys', 'DecoratedVideoPlayerService'];

  angular
    .module('videoPlayer')
    .controller('EventViewerLinkCtrl', EventViewerLinkCtrl)
    .component('eventViewerLink', {
      template: `
      <a ng-if="$ctrl.temPermissaoVisualizacaoVideo && $ctrl.shouldShowLink()"
        ng-class="{'video-link-assistido': $ctrl.isAssistido}"
        ng-mouseup="$ctrl.exibirPlayer($event)"
        uib-tooltip="{{ $ctrl.tooltip }}"
        tooltip-enable="$ctrl.showTooltip"
      >
        <span ng-if="$ctrl.snapshotId" class='material-symbols-filled'>imagesmode</span>
        <i ng-if="$ctrl.idVideo && !$ctrl.snapshotId" class='fa fa-play-circle video-button'/>
        {{ $ctrl.linkText() }}
      </a>
      `,
      controller: 'EventViewerLinkCtrl',
      bindings: {
        idVideo: '@',
        snapshotId: '@',
        datahora: '@',
        latitude: '@',
        longitude: '@',
        vehicleId: '@',
        vehiclePrefix: '@',
        vehiclePlate: '@',
        driverName: '@',
        tipoEvento: '@',
        threshold: '@',
        eventDuration: '@',
        actualValue: '@',
        eventLevel: '@',
        isPremiumTelemetryEnabled: '@',
        hideText: '@',
        showTooltip: '@',
        isAssistido: '<'
      }
    });
})();
