(function () {
  'use strict';

  class TiposEventosCtrl {
    constructor($scope, $uibModal, $document, $log, $window, Authenticator, safetyService, Restangular, AlertMessage, AlertasEnum, FeatureFlag,
      Keys, ServiceVfiltro, FactoryVfiltro, $translate, ConfigEventosService, FeatureOuService, FeaturehubJsSdk, FeatureHubConfigKeys) {
      this.scope = $scope;
      this.safetyIsCollapsed = false;
      this.inCabIsCollapsed = false;
      this.modalService = $uibModal;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.alertasEnum = AlertasEnum;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.translate = $translate;
      this.featureFlag = FeatureFlag;
      this.configEventosService = ConfigEventosService;
      this.authenticator = Authenticator;
      this.featureOuService = FeatureOuService;

      this.log = $log;
      this.window = $window;
      this.document = $document[0];
      this.safetyService = safetyService;
      this.safetyApp = 'safetyApp';
      this.pageComponent = './AlertsTag';
      this.safetyElement = 'safety-alerts-tag';
      this.component = null;
      this.container = this.document.getElementById(this.safetyElement);

      this.uoId = 0;
      this.roleCadastrar = true;
      this.model = {configuracoes: [], configuracoesSafety: [], configuracoesInCab: [], checkAllAlertas: false, checkAllVideos: false};
      this.mapLabel = [
        {tipo: 'possivelColisao', enum: this.alertasEnum.POSSIVEL_COLISAO, titulo: this.translate.instant(this.alertasEnum.POSSIVEL_COLISAO.descricao)},
        {tipo: 'remocaoEquipamento', enum: this.alertasEnum.REMOCAO_EQUIPAMENTO, titulo: this.translate.instant(this.alertasEnum.REMOCAO_EQUIPAMENTO.descricao)},
        {tipo: 'speedlimit', enum: this.alertasEnum.EXCESSO_VELOCIDADE, titulo: this.translate.instant(this.alertasEnum.EXCESSO_VELOCIDADE.descricao)},
        {tipo: 'speedlimitVia', enum: this.alertasEnum.EXCESSO_VELOCIDADE_POR_VIA, titulo: this.translate.instant(this.alertasEnum.EXCESSO_VELOCIDADE_POR_VIA.descricao)},
        {tipo: 'curva', enum: this.alertasEnum.CURVA_BRUSCA, titulo: this.translate.instant(this.alertasEnum.CURVA_BRUSCA.descricao)},
        {tipo: 'freada', enum: this.alertasEnum.FREADA_BRUSCA, titulo: this.translate.instant(this.alertasEnum.FREADA_BRUSCA.descricao)},
        {tipo: 'aceleracao', enum: this.alertasEnum.ACELERACAO_BRUSCA, titulo: this.translate.instant(this.alertasEnum.ACELERACAO_BRUSCA.descricao)},
        {tipo: 'banguela', enum: this.alertasEnum.BANGUELA, titulo: this.translate.instant(this.alertasEnum.BANGUELA.descricao)},
        {tipo: 'diagnosticosObd', enum: this.alertasEnum.DIAGNOSTICO_OBD, titulo: this.translate.instant(this.alertasEnum.DIAGNOSTICO_OBD.descricao)},
        {tipo: 'rpmExcessivo', enum: this.alertasEnum.EXCESSO_RPM, titulo: this.translate.instant(this.alertasEnum.EXCESSO_RPM.descricao)},
        {tipo: 'paradoLigado', enum: this.alertasEnum.PARADO_LIGADO, titulo: this.translate.instant(this.alertasEnum.PARADO_LIGADO.descricao)},
        {tipo: 'veiculoSemVigencia', enum: this.alertasEnum.VEICULO_SEM_VIGENCIA, titulo: this.translate.instant(this.alertasEnum.VEICULO_SEM_VIGENCIA.descricao)},
        {tipo: 'veiculoSemSinal', enum: this.alertasEnum.VEICULO_SEM_SINAL, titulo: this.translate.instant(this.alertasEnum.VEICULO_SEM_SINAL.descricao)},
        {tipo: 'manutencaoProgramada', enum: this.alertasEnum.MANUTENCAO_PROGRAMADA, titulo: this.translate.instant(this.alertasEnum.MANUTENCAO_PROGRAMADA.descricao)},
        {tipo: 'manutencaoProgramadaHorimetro', enum: this.alertasEnum.MANUTENCAO_PROGRAMADA_HORIMETRO, titulo: this.translate.instant(this.alertasEnum.MANUTENCAO_PROGRAMADA_HORIMETRO.descricao)},
        {tipo: 'distanciaPerigosa', enum: this.alertasEnum.DISTANCIA_PERIGOSA, titulo: this.translate.instant(this.alertasEnum.DISTANCIA_PERIGOSA.descricao)},
        {tipo: 'distracaoMotorista', enum: this.alertasEnum.DISTRACAO_MOTORISTA, titulo: this.translate.instant(this.alertasEnum.DISTRACAO_MOTORISTA.descricao)},
        {tipo: 'fadigaMotorista', enum: this.alertasEnum.FADIGA_MOTORISTA, titulo: this.translate.instant(this.alertasEnum.FADIGA_MOTORISTA.descricao)},
        {tipo: 'manuseioCelular', enum: this.alertasEnum.MANUSEIO_CELULAR, titulo: this.translate.instant(this.alertasEnum.MANUSEIO_CELULAR.descricao)},
        {tipo: 'usoCigarro', enum: this.alertasEnum.USO_CIGARRO, titulo: this.translate.instant(this.alertasEnum.USO_CIGARRO.descricao)},
        {tipo: 'motoristaAusente', enum: this.alertasEnum.MOTORISTA_AUSENTE, titulo: this.translate.instant(this.alertasEnum.MOTORISTA_AUSENTE.descricao)},
        {tipo: 'cameraObstruida', enum: this.alertasEnum.CAMERA_OBSTRUIDA, titulo: this.translate.instant(this.alertasEnum.CAMERA_OBSTRUIDA.descricao)},
        {tipo: 'permanenciaPonto', enum: this.alertasEnum.PERMANENCIA_PONTO, titulo: this.translate.instant(this.alertasEnum.PERMANENCIA_PONTO.descricao)},
        {tipo: 'semCinto', enum: this.alertasEnum.SEM_CINTO, titulo: this.translate.instant(this.alertasEnum.SEM_CINTO.descricao)},
        {tipo: 'rfidNaoCadastrado', enum: this.alertasEnum.RFID_NAO_CADASTRADO, titulo: this.translate.instant(this.alertasEnum.RFID_NAO_CADASTRADO.descricao)},
        {tipo: 'panico', enum: this.alertasEnum.PANICO, titulo: this.translate.instant(this.alertasEnum.PANICO.descricao)},
        {tipo: 'permanenciaCerca', enum: this.alertasEnum.PERMANENCIA_CERCA, titulo: this.translate.instant(this.alertasEnum.PERMANENCIA_CERCA.descricao)},
        {tipo: 'semCone', enum: this.alertasEnum.SEM_CONE, titulo: this.translate.instant(this.alertasEnum.SEM_CONE.descricao)},
        {tipo: 'semOculos', enum: this.alertasEnum.SEM_OCULOS, titulo: this.translate.instant(this.alertasEnum.SEM_OCULOS.descricao)},
        {tipo: 'semLuvas', enum: this.alertasEnum.SEM_LUVAS, titulo: this.translate.instant(this.alertasEnum.SEM_LUVAS.descricao)},
        {tipo: 'bocejo', enum: this.alertasEnum.BOCEJO, titulo: this.translate.instant(this.alertasEnum.BOCEJO.descricao)},
        {tipo: 'carona', enum: this.alertasEnum.CARONA, titulo: this.translate.instant(this.alertasEnum.CARONA.descricao)},
        {tipo: 'followingDistance', enum: this.alertasEnum.FOLLOWING_DISTANCE, titulo: this.translate.instant(this.alertasEnum.FOLLOWING_DISTANCE.descricao)},
        {tipo: 'stabilityControl', enum: this.alertasEnum.STABILITY_CONTROL, titulo: this.translate.instant(this.alertasEnum.STABILITY_CONTROL.descricao)},
        {tipo: 'collisionMitigation', enum: this.alertasEnum.COLLISION_MITIGATION, titulo: this.translate.instant(this.alertasEnum.COLLISION_MITIGATION.descricao)},
        {tipo: 'fcwBrakeExtAccDem', enum: this.alertasEnum.FCW_BRAKE_EXT_ACC_DEM, titulo: this.translate.instant(this.alertasEnum.FCW_BRAKE_EXT_ACC_DEM.descricao)},
        {tipo: 'hapticWarning', enum: this.alertasEnum.HAPTIC_WARNING, titulo: this.translate.instant(this.alertasEnum.HAPTIC_WARNING.descricao)}
      ];
      this.subItems = ['ultrapassagemIlegal'];
      this.alertsWithSubItem = [
        {name: 'distanciaPerigosa', subItem: 'ultrapassagemIlegal'}
      ];
      this.alertsDisableVideo = ['carona'];
      this.rideSpecificData = [
        {
          label: this.translate.instant(
            'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.ride.xOrMore', {x: 2}
          ),
          value: 2
        },
        {
          label: this.translate.instant(
            'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.ride.xOrMore', {x: 3}
          ),
          value: 3
        }
      ];
      this.alertsWithSpecificData = ['carona'];
      this.featurehubJsSdk = FeaturehubJsSdk;
      this.flagPremiumTelemetry = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.FEATURE_PREMIUM_TELEMETRY);

      this.serviceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        }
      ]))
      .then((filtro) => {
        this.roleCadastrar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('CONFIGURACAO_ALERTA_EDITAR');
        this.init(filtro.find(x => x.key === this.keys.uo.name).value.id);
      });
      this.collapseTables = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.COLLAPSIBLE_TABLES);

      this.scope.$on('$destroy', () => {
        this.unmountReactComponent();
      });
    }

    init(uoId) {
      this.uoId = uoId;

      if (this.featureFlag.SHOW_ULTRAPASSAGEM_ILEGAL) {
        this.handleFeatureFlag();
      }

      if (this.featurehubJsSdk.isFeatureEnabled('TTLAINT1548_save_and_read_alerts_tag')) {
        this.authenticator.getUser().then(user => {
          this.getEventsConfig(user);
        });
      }

      Promise.all([
        this.restangular.one(`configuracao/configuracoes/${this.uoId}/alerta`).get(),
        this.restangular.one(`sensor/uos/${this.uoId}/configuracoes/eventos`).get(),
        this.configEventosService.getConfigEventos()
      ])
      .then(([alertas, eventos, configEventos]) => {
        this.setConfiguracoesView(alertas, eventos, configEventos);
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: 'Ocorreu um erro ao carregar as configurações',
          appendTo: '.alerta-aqui-geral'
        });
      });
    }

    setConfiguracoesView(alertas, eventos, configEventos) {
      return this.scope.$apply(() => {
        this.model.configuracoesSafety = this.setConfiguracoes(alertas, eventos, configEventos, 'safety');
        this.model.configuracoesInCab = this.setConfiguracoes(alertas, eventos, configEventos, 'in-cab');
        this.model.configuracoes = [...this.model.configuracoesInCab, ...this.model.configuracoesSafety];
      });
    }

    getEventsConfig(user) {
      this.featureOuService.getEventsConfig(user.uo.id).then((eventNaConfig) => {
        if (!eventNaConfig) {
          this.loadReact(user);
        }
      });
    }

    loadReact(user) {
      this.safetyService
        .initialize()
        .then(() =>
          this.safetyService.getComponent(this.safetyApp, this.pageComponent)
        )
        .then((page) => {
          this.component = page().default;
          this.loadComponent(user);
        })
        .catch((error) => {
          this.log.error('Error occurred: ', error);
        });
    }

    loadComponent(user) {
      this.container = this.document.getElementById(this.safetyElement);
      this.window.ReactDOM.render(
        this.window.React.createElement(this.component, {
          ouId: this.uoId,
          user: user
        }),
        this.container
      );
    }

    setConfiguracoes(alertas, eventos, configEventos, category) {
      this.sortMapLabel();
      return this.mapLabel
        .filter(alertaMapeado => !configEventos[alertaMapeado.tipo] || configEventos[alertaMapeado.tipo].enabled !== false)
        .filter(alertaMapeado => alertaMapeado.enum.isEnabled(configEventos))
        .filter(alertaMapeado => alertas.configuracoes[alertaMapeado.tipo]
          ? alertas.configuracoes[alertaMapeado.tipo].configuracaoVisivel
          : null
        )
        .filter(alertaMapeado => alertaMapeado.enum.category === category)
        .map((item) => {
          if (item) {
            const [alerta, evento] = [alertas.configuracoes[item.tipo] || {}, eventos.configuracoes[item.tipo] || {}];
            return {
              item,
              alertaAtivo: !!alerta.ativo,
              eventoAtivo: evento.ativo,
              videoAtivo: !!alerta.videoAtivo,
              specificData: this.getSpecificData(alerta.specificDataHashMap, item.tipo, !!alerta.ativo)
            };
          }
        });
    }

    getSpecificData(specificDataHashMap, eventType, active) {
      switch (eventType) {
        case 'carona':
          const value = specificDataHashMap && specificDataHashMap.peopleCount;
          return angular.isDefined(value) && active ? value : null;
        default:
          return null;
      }
    }

    sortMapLabel() {
      this.mapLabel.sort((a, b) => a.titulo.localeCompare(b.titulo));
      this.alertsWithSubItem.forEach((item) => {
        let indexItem, indexSubItem;
        indexItem = this.mapLabel.findIndex((alerta) => alerta.tipo === item.name);
        indexSubItem = this.mapLabel.findIndex((alerta) => alerta.tipo === item.subItem);

        if (indexItem !== -1 && indexSubItem !== -1) {
          const [subItem] = this.mapLabel.splice(indexSubItem, 1);
          this.mapLabel.splice(indexItem + 1, 0, subItem);
        }
      });
      return this.mapLabel;
    }

    handleFeatureFlag() {
      const indexDistanciaPerigosa = this.mapLabel.findIndex(config => config.tipo === 'distanciaPerigosa');

      if (indexDistanciaPerigosa !== -1) {
        this.mapLabel.splice(indexDistanciaPerigosa + 1, 0, {
          tipo: 'ultrapassagemIlegal',
          enum: this.alertasEnum.ULTRAPASSAGEM_ILEGAL,
          titulo: this.translate.instant(this.alertasEnum.ULTRAPASSAGEM_ILEGAL.descricao)
        });
      }
    }

    configuracoesToObject() {
      return this.model.configuracoes.reduce(({accAlertas, accEventos}, config) => {
        let specificData = null;
        if (this.alertsWithSpecificData.includes(config.item.tipo)) {
          specificData = this.handleSpecificData(config.item.tipo, config.specificData);
        }
        accAlertas[config.item.tipo] = {
          ativo: !!config.alertaAtivo,
          videoAtivo: !!config.videoAtivo,
          specificDataHashMap: config.alertaAtivo ? specificData : null
        };
        if (angular.isDefined(config.eventoAtivo)) {
          accEventos[config.item.tipo] = {ativo: !!config.eventoAtivo};
        }
        return {accAlertas, accEventos};
      }, {accAlertas: {}, accEventos: {}});
    }

    handleSpecificData(eventType, value) {
      switch (eventType) {
        case 'carona':
          return {peopleCount: value};
        default:
          return null;
      }
    }

    getEventTooltip(item) {
      return item.enum && item.enum.tooltip ? this.translate.instant(item.enum.tooltip) : null;
    }

    isEventTooltip(item) {
      return !!item.enum.tooltip;
    }

    isSpeedLimitVia(item) {
      return item.tipo === 'speedlimitVia';
    }

    isPossivelColisao(item) {
      return item.tipo === 'possivelColisao';
    }

    isEPIEvent(item) {
      return item.tipo === 'semCone' || item.tipo === 'semOculos' || item.tipo === 'semLuvas';
    }

    isIlegalOvertaking(item) {
      return item.tipo === 'ultrapassagemIlegal';
    }

    isRideEvent(item) {
      return item.tipo === 'carona';
    }

    isFuncionalidadeExperimental(item) {
      return this.isEPIEvent(item) ||
        this.isPossivelColisao(item) ||
        this.isIlegalOvertaking(item) ||
        this.isRideEvent(item)
      ;
    }

    isSubItem(item) {
      return this.subItems.indexOf(item.tipo) > -1;
    }

    checkAlerta(alertConfig) {
      const matchedItem = this.alertsWithSubItem.find((alert) => alert.name === alertConfig.item.tipo);

      if (angular.isDefined(alertConfig.eventoAtivo) && alertConfig.alertaAtivo) {
        alertConfig.eventoAtivo = true;
      }

      if (!alertConfig.alertaAtivo) {
        alertConfig.videoAtivo = false;
      }

      if (matchedItem) {
        const subItem = this.model.configuracoes.find((config) => config.item.tipo === matchedItem.subItem);
        this.handleSubItemConfig(subItem, alertConfig.alertaAtivo);
      }
    }

    handleSubItemConfig(subItem, isAlertaAtivo) {
      if (!subItem) {
        return;
      }

      if (!isAlertaAtivo) {
        Object.assign(subItem, {alertaAtivo: false, isDisabled: true});
      } else {
        subItem.isDisabled = false;
      }
    }

    checkSubItemConfig(configSubItem) {
      if (!configSubItem.alertaAtivo) {
        configSubItem.videoAtivo = false;
      }
    }

    checkFirst(isFirst, config, value) {
      if (isFirst && config.specificData === null) {
        config.specificData = value;
      }
      return angular.isDefined(config.specificData) && config.specificData === value;
    }

    checkAllAlertas() {
      this.model.configuracoes.forEach((item) => item.alertaAtivo = this.model.checkAllAlertas);
    }

    checkAllVideos() {
      this.model.configuracoes.forEach((item) => {
        if (item.alertaAtivo && !this.disableVideo(item.item.tipo)) {
          item.videoAtivo = this.model.checkAllVideos;
        }
      });
    }

    disableVideo(type) {
      return this.alertsDisableVideo.includes(type);
    }

    abrirModal() {
      this.modalService.open({
        animation: true,
        templateUrl: 'configuracoes/eventos/modal/info/info-eventos.tpl.html',
        controller: 'ModalInfoEventosCtrl',
        controllerAs: '$ctrl',
        size: 'lg'
      });
    }

    salvar() {
      const configs = this.configuracoesToObject(),
          configAlertas = {
            uoId: this.uoId,
            configuracoes: configs.accAlertas
          },
          configEventos = {
            uo: {id: this.uoId},
            configuracoes: configs.accEventos
          };

      // ensure all alerts will be saved and which are not being shown on the screen will be saved as false - STFM-1207
      this.mapLabel.forEach(label => {
        if (!configAlertas.configuracoes[label.tipo]) {
          configAlertas.configuracoes[label.tipo] = {
            ativo: false,
            videoAtivo: false
          };
        }
      });

      // ensure all alerts will be saved and which are not being shown on the screen will be saved as false - STFM-1207
      this.mapLabel.forEach(label => {
        if (!configAlertas.configuracoes[label.tipo]) {
          configAlertas.configuracoes[label.tipo] = {
            ativo: false,
            videoAtivo: false
          };
        }
      });

      Promise.all([
        this.restangular.all(`configuracao/configuracoes/${this.uoId}/alerta`).customPUT(configAlertas),
        this.restangular.all(`sensor/uos/${this.uoId}/configuracoes/eventos`).post(configEventos)
      ]).then(() => {
        this.scope.formAlertas.$setPristine();

        this.scope.$apply(() => {
          this.alertMessage.create({
            type: 'success',
            message: 'Salvo com sucesso',
            appendTo: '.alerta-aqui'
          });
        });
      })
      .catch(() => {
        this.scope.$apply(() => {
          this.alertMessage.create({
            type: 'error',
            message: 'Ocorreu um erro ao salvar as configurações',
            appendTo: '.alerta-aqui'
          });
        });
      });
    }

    getGerarVideoText() {
      return this.flagPremiumTelemetry
        ? 'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.gerarVideo.tituloV2'
        : 'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.gerarVideo.titulo';
    }

    getTooltipText() {
      return this.flagPremiumTelemetry
        ? 'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.gerarVideo.infoExtraV2'
        : 'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.gerarVideo.infoExtra';
    }

    unmountReactComponent() {
      if (this.window.ReactDOM && this.container) {
        if (this.window.ReactDOM.unmountComponentAtNode(this.container)) {
          this.log.log('React component unmounted');
        } else {
          this.log.log('React component was not mounted.');
        }
      } else {
        this.log.log('Container not found or React component was not mounted.');
      }
    }

    collapse(category) {
      if (category === 'safety') {
        this.safetyIsCollapsed = !this.safetyIsCollapsed;
      } else if (category === 'inCab') {
        this.inCabIsCollapsed = !this.inCabIsCollapsed;
      }
    }
  }

  angular
    .module('SubpaginasEventos')
    .controller('TiposEventosCtrl', TiposEventosCtrl);
}());
